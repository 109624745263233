import React from 'react'

import './global.css'

const wrapRootElement = ({ element }) => {
  
  return (
    <>
      {element}
    </>
  );
};

export default wrapRootElement